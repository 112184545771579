import React, { useEffect, useLayoutEffect, useState } from "react"
import { useSpring, animated } from "react-spring"

import PropTypes from "prop-types"

const getInAnimation = inDirection => {
  switch (inDirection) {
    case "left":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(-100%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "right":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(100%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "up":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(-100%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "down":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(100%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    case "fade":
      return {
        fromDirection: {
          opacity: 0,
          transform: "translateX(0%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
    default:
      return {
        fromDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
        toDirection: {
          opacity: 1,
          transform: "translateX(0%) translateY(0%)",
        },
      }
  }
}

const Transition = props => {
  let { fromDirection } = getInAnimation(props.direction)

  const [animatedStyle, setAnimatedStyle] = useSpring(() => ({
    from: fromDirection,
    config: { mass: 1, tension: 280, friction: 60 },
  }))

  useLayoutEffect(() => {
    let { fromDirection, toDirection } = getInAnimation(props.direction)
    if (props.autoIn && !props.shouldExit) {
      setAnimatedStyle.start(toDirection)
      setIsAnimated(true)
    } else {
      setAnimatedStyle.start(fromDirection)
      setIsAnimated(true)
    }

    return () => {
      setAnimatedStyle.delete()
    }
  }, [props.autoIn, props.direction, props.shouldExit, setAnimatedStyle])

  const [isAnimated, setIsAnimated] = useState(false)

  // * Set opactiy to 1 after some time if bugged
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimated(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [props.direction, setAnimatedStyle])

  return (
    <animated.div
      className="animatedDivTransition"
      style={
        isAnimated
          ? !animatedStyle.transform.isAnimating && props.direction !== "fade"
            ? {}
            : animatedStyle
          : { opactiy: "1!important" }
      }
      // style={animatedStyle}
    >
      {props.children}
    </animated.div>
  )
}

Transition.propTypes = {
  children: PropTypes.node,
}

Transition.defaultProps = {
  children: false,
}

export default Transition
